export const INNER_WIDTH = window.innerWidth;
export const INNER_HEIGHT = window.innerHeight;

export const SCREEN = window.screen;

export const PERFORMANCE = window.performance;

export const NAVIGATOR = window.navigator;

export const CONNECTION =
  NAVIGATOR.connection || NAVIGATOR.mozConnection || NAVIGATOR.webkitConnection;

export const DEVICE_PIXEL_RATIO = window.devicePixelRatio || 1;

export function ADD_EVENT_LISTENER(eventName, cb, options) {
  return window.addEventListener(eventName, cb, options);
}

export function GET_VISIBILITY_STATE() {
  return document.visibilityState;
}

export function canTrackCwv() {
  return (
    'sendBeacon' in navigator &&
    'performance' in window &&
    'PerformanceObserver' in window
  );
}
