import {
  getCachedTTFB,
  getElementSignature,
  getLocation,
  SCREEN_SIZE,
  VIEWPORT_SIZE,
  getConnectionType,
  getDownlink,
  getRtt,
} from '../../_common/_utils.js';
import { DEVICE_PIXEL_RATIO, PERFORMANCE } from '../../_platform/window.js';

/**
 *  The payload will have the following shape:
 *
 *
 *  interface LCPRecord{
 *       lcpSignature: string;
 *       url: string;
 *       ttfb: number;
 *       connection: string;
 *       viewportSize: string;
 *       screenSize: string;
 *       pixelDensity: number;
 *       elements: LCPRecordElement[];
 *       downlink: number;
 *       rtt: number;
 *   }
 *
 *   interface LCPRecordElement{
 *       lcpRenderTime: number;
 *       lcpResponseEnd?: number;
 *       lcpRequestStart?: number;
 *   }
 *
 *  Once compressed, it will look like this:
 *
 *  [lcpSignature, url, ttfb, connection, viewportSize, screenSize, pixelDensity, [[lcpRenderTime, lcpResponseEnd, lcpRequestStart]], downlink, rtt]
 */

const DEFAULT_LCP_THRESHOLD = 2500;
const SIGNATURE_INDEX = 0;
const DOWNLINK_INDEX = 8;
const RTT_INDEX = 9;

function getTimingsForResource(resourceUrl) {
  function isSameResource(entry) {
    return entry.name === resourceUrl;
  }

  return PERFORMANCE.getEntriesByType('resource').find(isSameResource);
}

function updateLcpSignature(beaconData, signature) {
  if (beaconData[SIGNATURE_INDEX].length > 0) {
    beaconData[SIGNATURE_INDEX] += `|${signature}`;
  } else {
    beaconData[SIGNATURE_INDEX] = signature;
  }

  beaconData[DOWNLINK_INDEX] = getDownlink();
  beaconData[RTT_INDEX] = getRtt();
}

export function makeLcpTracker(beaconHandler, onLCP, userOptions) {
  const [scheduleBeacon] = beaconHandler;

  const recoredEntries = [];
  const options = userOptions || {};
  const ttfb = getCachedTTFB();

  let MIN_LCP_VALUE = options.threshold || DEFAULT_LCP_THRESHOLD;
  let LAST_LCP_RENDER_TIME = 0;
  let SENT = false;
  let SIGNATURE_MAKER = options.signatureMaker;

  const beaconData = [
    '',
    getLocation(),
    ttfb,
    getConnectionType(),
    VIEWPORT_SIZE,
    SCREEN_SIZE,
    Math.round(DEVICE_PIXEL_RATIO),
    recoredEntries,
    getDownlink(),
    getRtt(),
  ];

  function onSent() {
    SENT = true;
  }

  function handleSchedule() {
    if (SENT) {
      return;
    }
    const shouldSchedule = LAST_LCP_RENDER_TIME >= MIN_LCP_VALUE;
    scheduleBeacon('lcp', shouldSchedule, beaconData, onSent);
  }

  function processLcpEntry(lcpRecord) {
    const entries = lcpRecord.entries;
    const lcpEntry = entries[entries.length - 1];
    const elementSignature = getElementSignature(
      lcpEntry.element,
      SIGNATURE_MAKER
    );

    let lcpResEntry = {};

    if (lcpEntry.url) {
      lcpResEntry = getTimingsForResource(lcpEntry.url) || {};
    }

    const lcpRequestStart = Math.max(
      ttfb,
      lcpResEntry.requestStart || lcpResEntry.startTime || 0
    );

    const lcpResponseEnd = Math.max(
      lcpRequestStart,
      lcpResEntry.responseEnd || 0
    );

    const lcpRenderTime = Math.max(
      lcpResponseEnd,
      lcpEntry.renderTime || lcpEntry.loadTime || 0
    );

    const record = [parseInt(lcpRenderTime, 10)];

    if (lcpResponseEnd !== lcpRequestStart) {
      record.push(parseInt(lcpResponseEnd, 10), parseInt(lcpRequestStart, 10));
    }

    recoredEntries.push(record);
    updateLcpSignature(beaconData, elementSignature);

    LAST_LCP_RENDER_TIME = lcpRenderTime;
    handleSchedule();
  }

  onLCP(processLcpEntry);

  function setThreshold(newThreshold) {
    MIN_LCP_VALUE = newThreshold;
    handleSchedule();
  }

  function setSignatureMaker(override) {
    SIGNATURE_MAKER = override;
  }

  return { setThreshold, setSignatureMaker };
}
