import {
  getElementSignature,
  VIEWPORT_SIZE,
  SCREEN_SIZE,
  getLocation,
  getConnectionType,
  getDownlink,
  getRtt,
} from '../../_common/_utils.js';

const BEACONS_VALUE_INDEX = 1;
const TIMES_INDEX = 1;
const VALUE_INDEX = 2;
const DIFFERENCE_INDEX = 6;
const SIZES_INDEX = 7;
const DOWNLINK_INDEX = 8;
const RTT_INDEX = 9;

const DEFAULT_CLS_THRESHOLD = 0.1;
const DEFAULT_SOURCE_THRESHOLD = 0.01;

/**
 *  The payload will have the following shape:
 *
 *
 *  inteface CLSBeacon{
 *    url: string,
 *    record: CLSRecord[]
 *  }
 *
 *  interface CLSRecord{
 *       clsSignature: string;
 *       times: number;
 *       value: number;
 *       connection: string;
 *       viewportSize: string;
 *       screenSize: string;
 *       shifts:  [DomRect, DomRect][][][];
 *       sizes: [number, number][][]
 *       downlink: number;
 *       rtt: number;
 *   }
 *
 *   interface DomRect{
 *       bottom: number;
 *       top: number;
 *       left: number;
 *       right: number;
 *       height: number; //This height is not the element's actual height, but the height of the element in the viewport
 *       width: number; //Same as height
 *       x: number;
 *       y: number;
 *   }
 *
 *  Once compressed, it will look like this:
 *
 *  [url, [
 * [clsSignature, times, value, connection, viewportSize, screenSize, [ //all iterations
 *    [ // sources inside iteration
 *        [ //single source
 *           [top, bottom, left, right, height, width, x, y],
 *           [top, bottom, left, right, height, width, x, y]
 *        ]
 *    ]
 *  ],
 *  [ //all iterations
 *      [ // source inside iteration
 *          [width, height] // single element
 *      ]
 *  ], downlink, rtt
 * ]]
 */

function simplifyRect(rect) {
  return [
    parseInt(rect.top, 10),
    parseInt(rect.bottom, 10),
    parseInt(rect.left, 10),
    parseInt(rect.right, 10),
    parseInt(rect.height, 10),
    parseInt(rect.width, 10),
    parseInt(rect.x, 10),
    parseInt(rect.y, 10),
  ];
}

function getValuesFromAccumulator(payloadsMap, minSourceValue) {
  const values = [];

  function evaluateItem(payload) {
    const value = payload[VALUE_INDEX];
    if (value >= minSourceValue) {
      values.push(payload);
    }
  }

  payloadsMap.forEach(evaluateItem);
  return values;
}

function cleanAccumulatedPayloads(payloadsMap, minSourceValue) {
  function evaluateAndClean(payload, key, map) {
    const value = payload[VALUE_INDEX];
    if (value >= minSourceValue) {
      map.delete(key);
    }
  }
  payloadsMap.forEach(evaluateAndClean);
}

export function makeClsTracker(beaconHandler, onCLS, userOptions) {
  const [scheduleBeacon] = beaconHandler;

  const options = userOptions || {};

  const BEACON_DATA = [getLocation(), []];

  const STORED_PAYLOADS = new Map();

  let SIGNATURE_MAKER = options.signatureMaker;
  let MIN_TOTAL_VALUE = options.totalThreshold || DEFAULT_CLS_THRESHOLD;
  let MIN_RECORD_COMMULATIVE_VALUE =
    options.sourceThreshold || DEFAULT_SOURCE_THRESHOLD;

  function sourceReducer(acum, source) {
    const { node, currentRect, previousRect } = source;
    const signature =
      getElementSignature(node, SIGNATURE_MAKER) || 'NO_ELEMENT';
    let width = -1;
    let height = -1;

    if (node) {
      //ToDo we possibly need more extensive checks for different types of nodes
      const sizeableNode =
        node.nodeType === Node.TEXT_NODE ? node.parentElement : node;

      if (sizeableNode && sizeableNode.getBoundingClientRect) {
        const nodeRect = sizeableNode.getBoundingClientRect();
        width = parseInt(nodeRect.width, 10);
        height = parseInt(nodeRect.height, 10);
      }
    }

    return [
      [...acum[0], signature],
      [...acum[1], [simplifyRect(previousRect), simplifyRect(currentRect)]],
      [...acum[2], [width, height]],
    ];
  }

  function processClsEntry(clsRecord) {
    const { entries, value: overallValue } = clsRecord;
    const lastEntry = entries[entries.length - 1];
    if (!lastEntry) {
      return;
    }
    const { sources, value } = lastEntry;
    const [signatures, differences, sizes] = sources.reduce(sourceReducer, [
      [],
      [],
      [],
    ]);
    const signature = signatures.join('|') || 'NO_SOURCES';

    let record;

    if (STORED_PAYLOADS.has(signature)) {
      record = STORED_PAYLOADS.get(signature);
      record[TIMES_INDEX]++;
      record[VALUE_INDEX] += value;
      record[DIFFERENCE_INDEX].push(differences);
      record[SIZES_INDEX].push(sizes);
      record[DOWNLINK_INDEX] = getDownlink();
      record[RTT_INDEX] = getRtt();
    } else {
      record = [
        signature,
        1,
        value,
        getConnectionType(),
        VIEWPORT_SIZE,
        SCREEN_SIZE,
        [differences],
        [sizes],
        getDownlink(),
        getRtt(),
      ];
      STORED_PAYLOADS.set(signature, record);
    }

    const scheduledValues = getValuesFromAccumulator(
      STORED_PAYLOADS,
      MIN_RECORD_COMMULATIVE_VALUE
    );

    function cleanPayloads() {
      cleanAccumulatedPayloads(STORED_PAYLOADS, MIN_RECORD_COMMULATIVE_VALUE);
    }

    BEACON_DATA[BEACONS_VALUE_INDEX] = scheduledValues;
    scheduleBeacon(
      'cls',
      overallValue >= MIN_TOTAL_VALUE && scheduledValues.length > 0,
      BEACON_DATA,
      cleanPayloads
    );
  }

  onCLS(processClsEntry);

  function setThresholds(thresholds) {
    MIN_TOTAL_VALUE = thresholds[0];
    MIN_RECORD_COMMULATIVE_VALUE = thresholds[1];
  }

  function setSignatureMaker(override) {
    SIGNATURE_MAKER = override;
  }

  return { setThresholds, setSignatureMaker };
}
