import {
  NAVIGATOR,
  ADD_EVENT_LISTENER,
  GET_VISIBILITY_STATE,
} from '../_platform/window.js';

function defaultRateController() {
  return true;
}

function prepareBeacons(callback) {
  ADD_EVENT_LISTENER('visibilitychange', callback, true);
  ADD_EVENT_LISTENER('pagehide', callback, true);
}

function fireCallback(cb) {
  cb();
}

function fireCallbacks(beaconKey, callbackMap) {
  if (!callbackMap.has(beaconKey)) {
    return;
  }
  const set = callbackMap.get(beaconKey);
  set.forEach(fireCallback);
  set.clear();
}

function saveCallback(beaconMap, beaconName, callback) {
  let set;

  if (beaconMap.has(beaconName)) {
    set = beaconMap.get(beaconName);
  } else {
    set = new Set();
    beaconMap.set(beaconName, set);
  }

  set.add(callback);
}

function makeBeaconPair(pharosUrl, name, data) {
  return [new URL(`${pharosUrl}/${name}`).href, JSON.stringify(data)];
}

export default function makeBeaconHandler(pharosUrl, shouldSend) {
  const ON_BEACON_SENT_CALLBACKS = new Map();
  const LATE_BEACONS = new Map();
  const rateController = shouldSend || defaultRateController;

  function fireLateBeacons() {
    LATE_BEACONS.forEach(fireBeacon);
    LATE_BEACONS.clear();
  }

  function scheduleBeacon(beaconName, shouldSchedule, data, callback) {
    if (callback) {
      saveCallback(ON_BEACON_SENT_CALLBACKS, beaconName, callback);
    }
    if (shouldSchedule) {
      LATE_BEACONS.set(beaconName, makeBeaconPair(pharosUrl, beaconName, data));
    } else {
      LATE_BEACONS.delete(beaconName);
    }
  }

  function fireBeacon(beaconPair, beaconKey) {
    const [url, body] = beaconPair;
    if (rateController(url)) {
      NAVIGATOR.sendBeacon(url, body);
    }
    fireCallbacks(beaconKey, ON_BEACON_SENT_CALLBACKS);
  }

  function onHiddenOrPageHide(event) {
    if (event.type === 'pagehide' || GET_VISIBILITY_STATE() === 'hidden') {
      fireLateBeacons();
    }
  }

  prepareBeacons(onHiddenOrPageHide);

  return [scheduleBeacon];
}
