import {
  INNER_HEIGHT,
  INNER_WIDTH,
  PERFORMANCE,
  SCREEN,
  CONNECTION,
} from '../_platform/window.js';

function processClassList(classList) {
  return classList.sort().join('.');
}

const UNATTRIBUTABLE = 'unattributable';

export function getElementSignature(element, override) {
  if (!element || !(element instanceof Node)) {
    return UNATTRIBUTABLE;
  }
  let { tagName, classList, id } = element;
  classList = classList && classList.length > 0 ? Array.from(classList) : [];

  if (override) {
    [id, tagName, classList] = override(id, tagName, classList);
  }

  let className = processClassList(classList);
  let signature;

  if (!id && !className) {
    signature = `${getElementSignature(element.parentNode, override)}${
      tagName ? `>${tagName}` : ''
    }`;
  } else {
    signature = `${tagName}${id ? `#${id}` : ''}${
      className ? `.${className}` : ''
    }`;
  }

  return signature || UNATTRIBUTABLE;
}

export const V = 1;

export const VIEWPORT_SIZE = `${INNER_WIDTH}x${INNER_HEIGHT}`;
export const SCREEN_SIZE = SCREEN ? `${SCREEN.width}x${SCREEN.height}` : '0x0';

let _storedTTFB = 0;

export function getCachedTTFB() {
  if (_storedTTFB) {
    return _storedTTFB;
  }

  const navigationEntries = PERFORMANCE.getEntriesByType('navigation');

  if (navigationEntries.length > 0) {
    _storedTTFB = parseInt(navigationEntries[0].responseStart || 0, 10);
  }
  return _storedTTFB;
}

let _sessionId = null;

export function getSessionId() {
  if (_sessionId == null) {
    _sessionId = `${Date.now()}-${
      Math.floor(Math.random() * (9e12 - 1)) + 1e12
    }`;
  }
  return _sessionId;
}

/**
 * We cache it because of
 * https://web.dev/vitals-spa-faq/#do-core-web-vitals-metrics-include-spa-route-transitions
 */

const storedLocation = location.href;

export function getLocation() {
  return storedLocation;
}

export function getConnectionType() {
  return CONNECTION && 'effectiveType' in CONNECTION
    ? CONNECTION.effectiveType
    : '4g';
}

export function getDownlink() {
  return CONNECTION && 'downlink' in CONNECTION ? CONNECTION.downlink : -1;
}

export function getRtt() {
  return CONNECTION && 'rtt' in CONNECTION ? CONNECTION.rtt : -1;
}
