import {
  getCachedTTFB,
  getElementSignature,
  getLocation,
  SCREEN_SIZE,
  VIEWPORT_SIZE,
  getConnectionType,
  getDownlink,
  getRtt,
} from '../../_common/_utils.js';

/**
 *  The payload will have the following shape:
 *
 *
 *  interface INPReport{
 *       inpSignature: string;
 *       url: string;
 *       event: string;
 *       value: string;
 *       ttfb: string;
 *       connection: string;
 *       viewportSize: string;
 *       screenSize: string;
 *       downlink: number;
 *       rtt: number;
 *   }
 *
 *  Once compressed, it will look like this:
 *
 *  [inpSignature, url, event, value, ttfb, connection, viewportSize, screenSize, downlink, rtt]
 */

const DEFAULT_INP_THRESHOLD = 200;
const SIGNATURE_INDEX = 0;
const EVENT_INDEX = 2;
const VALUE_INDEX = 3;
const DOWNLINK_INDEX = 8;
const RTT_INDEX = 9;

function findEntryWithElement(inpEntry) {
  return !!inpEntry.target;
}

export function makeInpTracker(beaconHandler, onINP, userOptions) {
  const [scheduleBeacon] = beaconHandler;

  const options = userOptions || {};
  const ttfb = getCachedTTFB();

  let MIN_INP_VALUE = options.threshold || DEFAULT_INP_THRESHOLD;
  let SIGNATURE_MAKER = options.signatureMaker;

  const beaconData = [
    '',
    getLocation(),
    '',
    0,
    ttfb,
    getConnectionType(),
    VIEWPORT_SIZE,
    SCREEN_SIZE,
    getDownlink(),
    getRtt(),
  ];

  function handleSchedule() {
    const shouldSchedule = beaconData[VALUE_INDEX] >= MIN_INP_VALUE;
    scheduleBeacon('inp', shouldSchedule, beaconData);
  }

  function processInpEntry(inpRecord) {
    const inpValue = inpRecord.value;
    const entries = inpRecord.entries;

    if (inpRecord.value < beaconData[VALUE_INDEX]) {
      return;
    }

    const inpEntry = [...entries].reverse().find(findEntryWithElement);

    if (!inpEntry) {
      return;
    }

    const elementSignature = getElementSignature(
      inpEntry.target,
      SIGNATURE_MAKER
    );

    beaconData[SIGNATURE_INDEX] = elementSignature;
    beaconData[EVENT_INDEX] = inpEntry.name;
    beaconData[VALUE_INDEX] = inpValue;
    beaconData[DOWNLINK_INDEX] = getDownlink();
    beaconData[RTT_INDEX] = getRtt();

    handleSchedule();
  }

  onINP(processInpEntry);

  function setThreshold(newThreshold) {
    MIN_INP_VALUE = newThreshold;
    handleSchedule();
  }

  function setSignatureMaker(override) {
    SIGNATURE_MAKER = override;
  }

  return { setThreshold, setSignatureMaker };
}
