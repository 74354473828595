import { onLCP, onCLS, onFCP, onFID, onINP } from 'web-vitals';

import { makeLcpTracker } from './lcp.js';
import { makeCwvTracker } from './cwv.js';
import { makeClsTracker } from './cls.js';
import { makeInpTracker } from './inp.js';

import makeBeaconHandler from '../../_common/_beacon.js';
import { canTrackCwv } from '../../_platform/window.js';

const DEFAULT_PHAROS_URL = `https://collect.rebelmouse.io/collector/v1`;

const WEB_VITALS_OPTIONS = { reportAllChanges: true };

function subscribeToLCP(cb) {
  onLCP(cb, WEB_VITALS_OPTIONS);
}

function subscribeToCLS(cb) {
  onCLS(cb, WEB_VITALS_OPTIONS);
}

function subscribeToFID(cb) {
  onFID(cb, WEB_VITALS_OPTIONS);
}

function subscribeToINP(cb) {
  onINP(cb, WEB_VITALS_OPTIONS);
}

function subscribeToFCP(cb) {
  onFCP(cb, WEB_VITALS_OPTIONS);
}

export default function startTracking(userOptions) {
  if (!canTrackCwv()) {
    return { ready: false };
  }

  const options = userOptions || {};

  const pharosUrl = options.pharosUrl || DEFAULT_PHAROS_URL;
  const beaconHandler = makeBeaconHandler(pharosUrl, options.rateController);

  const { lcpTrackerOptions, clsTrackerOptions, inpTrackerOptions } =
    options || {};

  const lcpTracker = makeLcpTracker(
    beaconHandler,
    subscribeToLCP,
    lcpTrackerOptions
  );

  const inpTracker = makeInpTracker(
    beaconHandler,
    subscribeToINP,
    inpTrackerOptions
  );

  const cwvTracker = makeCwvTracker(
    beaconHandler,
    subscribeToLCP,
    subscribeToCLS,
    subscribeToFID,
    subscribeToINP,
    subscribeToFCP
  );

  const clsTracker = makeClsTracker(
    beaconHandler,
    subscribeToCLS,
    clsTrackerOptions
  );

  return { ready: true, clsTracker, lcpTracker, cwvTracker, inpTracker };
}
