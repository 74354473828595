import {
  getCachedTTFB,
  getLocation,
  getSessionId,
} from '../../_common/_utils.js';

const METRIC_INDEXES = {
  LCP: 2,
  CLS: 3,
  FID: 4,
  TTFB: 5,
  INP: 6,
  FCP: 7,
};

export function makeCwvTracker(
  beaconHandler,
  onLCP,
  onCLS,
  onFID,
  onINP,
  onFCP
) {
  const [scheduleBeacon] = beaconHandler;

  const BEACON_DATA = [
    getSessionId(), // session id
    getLocation(), // location
    null, //lcp
    null, //cls
    null, //fid
    getCachedTTFB(), //ttfb
    null, //inp
    null, //fcp
  ];

  function storeValue(metric) {
    const { name, value } = metric;
    const index = METRIC_INDEXES[name];
    BEACON_DATA[index] = name === 'CLS' ? value : Math.round(value);
    scheduleBeacon('cwv', true, BEACON_DATA);
  }

  onLCP(storeValue);
  onCLS(storeValue);
  onFID(storeValue);
  onINP(storeValue);
  onFCP(storeValue);

  return true;
}
